
import {Component, Prop, Watch} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import Company from "@/models/Company";
import OnBoardingProgress from "@/models/OnBoardingProgress";
import {ROUTE_APP_DOWNLOAD, ROUTE_COMPANY_OVERVIEW, ROUTE_CONTAINERS_OVERVIEW, ROUTE_PROFILE} from "@/router/routes";

@Component
export default class StepByStepAgentComponent extends mixins(ErrorMessageHandlerMixin) {
  @Prop({required: true})
  private company!: Company;

  /**
   * flag that indicates if the user has registered inside the app
   * @private
   */
  private isRegistered: boolean = true;

  /**
   * flag that indicates if the user has added a payment
   * @private
   */
  private hasAddedPayment: boolean = false;

  /**
   * flag that indicates if a container was created
   * @private
   */
  private createdContainer: boolean = false;

  /**
   * flag that indicates if the app was downloaded
   * @private
   */
  private downloadedApp: boolean = false;

  private created() {
    this.updateCompanyProgress(this.company);
  }

  @Watch('company')
  private onCompanyChanged() {
    this.updateCompanyProgress(this.company);
  }

  private updateCompanyProgress(company: Company) {
    const progress: OnBoardingProgress = this.company.onboardingProgress;
    this.hasAddedPayment = progress.paymentAdded;
    this.createdContainer = progress.containerAdded;
    this.downloadedApp = progress.appDownloaded;
  }

  private onAddPayment() {
    if(this.$route.name === ROUTE_PROFILE) return;
    this.$router.replace({name: ROUTE_PROFILE, params: {id: this.$route.params.id}, query: {state: 'PAYMENT'}});
  }

  private onAddContainer() {
    if(this.$route.name === ROUTE_CONTAINERS_OVERVIEW) return;
    this.$router.push({name: ROUTE_CONTAINERS_OVERVIEW, params: {id: this.$route.params.id}});
  }

  private onDownloadApp() {
    if(this.$route.name == ROUTE_APP_DOWNLOAD) return;
    this.$router.push({name: ROUTE_APP_DOWNLOAD, params: {id: this.$route.params.id}});
  }
}
